import { getNavigationOffset, getUrlParamByName } from '@/utils/app';
import Scroller from '@/plugins/scroller';
import { throttle, uniq } from 'lodash';

function navigation() {
  return {
    isOpen: false,
    lastTarget: '',
    scroller: new Scroller(),
    init() {
      this.highlightActiveNavEl();
      this.addEventListeners();
    },
    addEventListeners() {
      // Binds click handler to nav items to get a fancy scroll animation
      const navItems = (this as any).$el.querySelectorAll(
        '[data-ref="navigationItem"]',
      );
      navItems.forEach((item: any) => {
        item.addEventListener('click', (e: any) => {
          const itemHref = item.getAttribute('href');
          if (itemHref) {
            const target = this.getTargetFromHref(itemHref);
            if (target) this.scroller.scrollTo(`#${target}`);
          }
          e.preventDefault();
        });
      });
      // Binds to scroll
      window.addEventListener(
        'scroll',
        throttle(this.highlightActiveNavEl.bind(this), 50),
      );
    },
    highlightActiveNavEl() {
      const navHeight = getNavigationOffset();
      const navItems = (this as any).$el.querySelectorAll(
        '[data-ref="navigationItem"]',
      );
      if (!navItems.length) return;
      const scrollItems = uniq(
        Array.from(navItems).map((item: any) => {
          const itemHref = item.getAttribute('href');
          if (itemHref) {
            const target = this.getTargetFromHref(itemHref);
            if (target) return target;
          }
        }),
      );
      if (!scrollItems.length) return;
      // Gets window scroll position
      const fromTop = window.pageYOffset + navHeight;
      // Gets array of ids from current scroll items
      const currentArray = scrollItems.filter((item) => {
        const section = document.getElementById(item as string);
        if (section && section.offsetTop <= fromTop) return item;
      });
      if (!currentArray.length) return;
      // Gets the id of the current element
      const currentTarget = currentArray[currentArray.length - 1];

      if (this.lastTarget !== currentTarget) {
        this.lastTarget = currentTarget as string;
        // Sets & removes active class
        navItems.forEach((item: any) => item.classList.remove('active'));
        const curNavItems = Array.from(navItems).filter((item: any) => {
          const itemHref = item.getAttribute('href');
          return this.getTargetFromHref(itemHref) === currentTarget;
        });
        curNavItems.forEach((item: any) => item.classList.add('active'));
      }
    },
    getTargetFromHref(href: string) {
      return getUrlParamByName('scrollTo', `${window.location.origin}${href}`);
    },
  };
}

(window as any).navigation = navigation;
