const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};
const colors = {
  primary: {
    dark: '#95170F',
    base: '#D62116',
    light: '#E2635B',
    lighter: '#EEA6A1',
    lightest: '#FAE8E7',
  },
  text: {
    base: '#1E1E1C',
    gray: '#8C8C8C',
    inverted: '#fff',
  },
  background: {
    base: 'white',
    gray: '#F2F2F2',
    'gray-dark': '#E8E8E8',
  },
  border: {
    base: '#DEDEDE',
  },
};
const spacing = {
  base: '20px',
  xs: '5px',
  sm: '10px',
  md: '20px',
  lg: '30px',
  xl: '50px',
  '2xl': '90px',
  '3xl': '150px',
  '4xl': '300px',
};

const pristineConfig = {
  screens,
  bootstrapBreakpoints: {
    min: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  gutters: {
    ...spacing,
  },
  colors,
  spacing,
  backgroundColor: colors,
  borderColor: colors,
  ringColor: colors,
  fontSize: {
    sm: ['14px', { lineHeight: '20px' }],
    base: ['16.5px', { lineHeight: '20px', letterSpacing: '0.2px' }],
    md: ['20px', { lineHeight: '40px', letterSpacing: '2.5px' }],
    md2: ['20px', { lineHeight: '22px', letterSpacing: '0.2px' }],
    lg: ['23px', { lineHeight: '25px' }],
    xl: ['25px', { lineHeight: '47.8px' }],
    xl2: ['25px', { lineHeight: '28px', letterSpacing: '1.5px' }],
    xl3: ['25px', { lineHeight: '28px', letterSpacing: '0.2px' }],
    '2xl': ['32px', { lineHeight: '32.17px' }],
    '3xl': ['35px', { lineHeight: '47px', letterSpacing: '4px' }],
  },
  fontFamily: {
    sans: [
      'Proxima Nova',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  height: {
    menu: {
      mobile: '76px',
      desktop: '96px',
    },
    hero: '450px',
    'image-base': '140px',
    'image-lg': '250px',
  },
  zIndex: {
    '-10': '-10',
  },
};

module.exports = pristineConfig;
