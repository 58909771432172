import { getUrlParamByName } from '@/utils/app';
import Scroller from '@/plugins/scroller';

window.addEventListener('load', () => {
  const sectionToScroll = getUrlParamByName('scrollTo');
  if (sectionToScroll) {
    const scroller = new Scroller();
    window.setTimeout(() => {
      scroller.scrollTo(`#${sectionToScroll}`);
    }, 250);
  }
});
